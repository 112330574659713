import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../../components/Layout';
import '../../components/all.sass';
import logo from '../../img/logo.svg';
import bg from './bg-img.png';
import camBw from './CAM-symbol-bw.png';
import camColor from './CAM-symbol-color.png';
import rspBw from './RSP-symbol-bw.png';
import rspColor from './RSP-symbol-color.png';
import entBw from './ENT-symbol-bw.png';
import entColor from './ENT-symbol-color.png';

const LoginPage = () => (
  <Layout contactus={true} customTitle="Please select a FilmChain product">
    <Wrapper>
      <Link to="/">
        <img src={logo} alt="FilmChain" className="filmchain-icon" />
      </Link>
      <Content>
        <StyledA href="https://app.filmchain.co" rel="noopener noreferrer">
          <Container>
            <CAM id="cam" />
            <p class="is-size-5-desktop is-size-7 has-text-weight-bold margin-top-1">
              Collection Account Management
            </p>
            <p class="is-size-6-desktop is-size-8 text-center margin-top-05 light">
              Collect revenues and pay production beneficiaries in an efficient, transparent and
              automatic manner.
            </p>
            <button className="btn tertiary small margin-top-2">Log in</button>
          </Container>
        </StyledA>
        <StyledA href="https://reporting.filmchain.co" rel="noopener noreferrer">
          <Container>
            <RSP id="rsp" />
            <p class="is-size-5-desktop is-size-7 has-text-weight-bold margin-top-1">
              Reporting Software for Producers
            </p>
            <p class="is-size-6-desktop is-size-8 text-center margin-top-05 light">
              Clear and concise reporting on revenue and performance data, from producers to authors
              and performers.
            </p>
            <button className="btn tertiary small margin-top-2">Log in</button>
          </Container>
        </StyledA>
        <StyledA href="https://enterprise.filmchain.co" rel="noopener noreferrer">
          <Container>
            <ENT id="ent" />
            <p class="is-size-5-desktop is-size-7 has-text-weight-bold margin-top-1">Enterprise</p>
            <p class="is-size-6-desktop is-size-8 text-center margin-top-05 light">
              Royalties management solution automating financial reporting to licensors.
            </p>
            <button className="btn tertiary small margin-top-2">Log in</button>
          </Container>
        </StyledA>
      </Content>
      <div></div>
    </Wrapper>
  </Layout>
);

export default LoginPage;

const Wrapper = styled.div`
  background-image: url(${bg});
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 8rem;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-gap: 16px;
  flex-wrap: wrap;
`;
const Container = styled.div`
  border-radius: 16px;
  padding: 2rem;
  padding-bottom: 7rem;
  max-width: 418px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    display: none;
  }
  :hover {
    background: rgba(255, 255, 255, 0.03);
    padding-bottom: 2rem;
    p {
      color: #ffffffcc;
    }
    button {
      display: initial;
    }
    #cam {
      background-image: url(${camColor});
    }
    #rsp {
      background-image: url(${rspColor});
    }
    #ent {
      background-image: url(${entColor});
    }
  }
`;
const StyledA = styled.a`
  color: rgb(255, 255, 255);
  :hover {
    color: rgb(255, 255, 255);
  }
`;
const CAM = styled.div`
  width: 276px;
  height: 102px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${camBw});
`;
const RSP = styled.div`
  width: 276px;
  height: 102px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${rspBw});
`;
const ENT = styled.div`
  width: 276px;
  height: 102px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${entBw});
`;
